import React, { useState, useEffect } from 'react';
import { Table, Select, Button, Box, Textarea } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../components/TextEditor/TextEditor';

type LawyerTableProps = {
  lawyerList: { [key: string]: string };
  selectedLawyers: { [key: string]: { name: string; bio: string } };
  setSelectedLawyers: React.Dispatch<React.SetStateAction<{ [key: string]: { name: string; bio: string } }>>;
};

const LawyerTable: React.FC<LawyerTableProps> = ({ lawyerList, selectedLawyers, setSelectedLawyers }) => {
  const [rows, setRows] = useState<string[]>(['']);
  const [maxWidth, setMaxWidth] = useState<string>('auto');
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(selectedLawyers).length === 0) {
      setRows(['']);
    } else {
      setRows(Object.keys(selectedLawyers));
    }

    const longestLawyerName = Object.values(lawyerList).reduce((longest, current) =>
      current.length > longest.length ? current : longest, ''
    );
    const maxLength = longestLawyerName.length * 8;
    setMaxWidth(`${maxLength}px`);
  }, [lawyerList, selectedLawyers]);

  const addRow = () => setRows((prev) => [...prev, '']);

  const handleSelectChange = (lawyerId: string, rowIndex: number) => {
    const lawyerName = lawyerList[lawyerId];
    setSelectedLawyers((prev) => ({
      ...prev,
      [lawyerId]: { name: lawyerName, bio: prev[lawyerId]?.bio || '' },
    }));
    setRows((prev) => prev.map((row, index) => (index === rowIndex ? lawyerId : row)));
  };

  const handleBioChange = (lawyerId: string, bio: string) => {
    setSelectedLawyers((prev) => ({
      ...prev,
      [lawyerId]: { ...prev[lawyerId], bio },
    }));
  };

  const removeRow = (rowIndex: number) => {
    const lawyerId = rows[rowIndex];
    setRows((prev) => prev.filter((_, index) => index !== rowIndex));
    setSelectedLawyers((prev) => {
      const updated = { ...prev };
      delete updated[lawyerId];
      return updated;
    });
  };

  const getAvailableLawyers = (currentLawyerId: string) => {
    const selectedLawyerIds = new Set(Object.keys(selectedLawyers));
    return Object.keys(lawyerList)
      .filter((key) => key === currentLawyerId || !selectedLawyerIds.has(key))
      .map((key) => ({
        value: key,
        label: lawyerList[key],
      }));
  };

  return (
    <div>
      <Table>
        <tbody>
          {rows.map((lawyerId, index) => (
            <tr key={index}>
              <td>
                <Box style={{ position: 'relative', paddingBottom: '1rem' }}>
                  {/* Lawyer Select Dropdown */}
                  <Select
                    placeholder="Pick a lawyer"
                    data={getAvailableLawyers(lawyerId)}
                    value={lawyerId || ''}
                    onChange={(value) => handleSelectChange(value || '', index)}
                    style={{ width: maxWidth, marginBottom: '0.5rem' }}
                  />
                  {/* TextEditor for Bio */}
                  {/* <TextEditor
                    content={selectedLawyers[lawyerId]?.bio || ''} // Ensure bio is a string
                    onUpdate={(value) => handleBioChange(lawyerId, value || '')} // Handle bio updates
                    placeholder="Add bio"
                    disabled={!lawyerId} // Disable the TextEditor if no lawyer is selected
                  /> */}
                  <Textarea
                    value={selectedLawyers[lawyerId]?.bio || ''}
                    placeholder={t('addBio')}
                    onChange={(event) => handleBioChange(lawyerId, event.target.value || '')}
                    variant="filled"
                    autosize
                    minRows={6}
                  />
                  {/* Remove Button */}
                  <Button
                    variant="light"
                    color="red"
                    size="compact-xs"
                    onClick={() => removeRow(index)}
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      zIndex: 10,
                    }}
                  >
                    <IconX size={16} />
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="light" onClick={addRow} mt="md">
        {t('addLawyer')}
      </Button>
    </div>
  );
};

export default LawyerTable;
