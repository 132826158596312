export async function authPost(accessToken: string, path: string, body: any) {
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });
    const name = await res.json();
    return name;
  } catch (err) {
    console.error(
      `POST request to uri ${uri} with body:\n${JSON.stringify(body, undefined, 4)} failed`
    );
    console.error(err);
    return;
  }
}

export async function authGet(accessToken: string, path: string) {
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const res = await fetch(uri, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const name = await res.json();
    return name;
  } catch (err) {
    console.log(`GET request to uri ${uri} failed`);
    return;
  }
}

export function authWebSocket(accessToken: string): WebSocket {
  const wsDomain = 'wss://tbkinro0j5.execute-api.eu-west-2.amazonaws.com/production';
  const tokenDomain = wsDomain + '?token=' + accessToken;
  console.log(tokenDomain);

  console.log(`Connecting to WebSocket at ${wsDomain}`);

  const ws = new WebSocket(tokenDomain);

  // ws.onopen = () => {
  //   console.log(`WebSocket connection to ${wsDomain} opened`);
  //   // ws.send(JSON.stringify({ action: 'Hello' }));
  //   console.log('Message sent');
  // };

  // ws.onmessage = (event) => {
  //   console.log(`Message from server: ${event.data}`);
  // };

  ws.onerror = (error) => {
    console.log(error);
    console.error(`WebSocket error: ${error}`);
  };

  ws.onclose = (event) => {
    console.log(event);
    console.log(`WebSocket connection to ${wsDomain} closed: ${event.reason}`);
  };

  return ws;
}
