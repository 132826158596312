import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Divider, Input, Modal, Textarea, TextInput, Title, Tooltip } from '@mantine/core';
import { Button, Loader } from '@mantine/core';
import { IconTrash, IconPlus, IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchProposal,
  saveProposal,
  generateWordDoc,
  deleteProposal,
  getLawyerList,
} from '@/services/proposalsRoutes';

import ExperienceTable from './ExperienceTable';

import { Proposal } from '../../interfaces/types';
import FeeTable from './FeeTable';
import { FeeProposal, Experience, Representative } from './types';
// import RichText from './RichText';
import LawyerTable from './LawyerTable';
import EditableTable from './EditableTable';

import { authWebSocket } from '../../auth/authFetches';

import { useTranslation } from 'react-i18next';

// const { t } = useTranslation();

// Define action types
type InitialisationAction<T> = {
  type: 'initialisation';
  payload: T[];
};

type AddAction<T> = {
  type: 'add';
  payload: T;
};

type RemoveAction<T> = {
  type: 'remove';
  payload: T;
};

// Combine action types into a union type
type Action<T> = InitialisationAction<T> | AddAction<T> | RemoveAction<T>;

function feeReducer(state: FeeProposal[], action: Action<FeeProposal>): FeeProposal[] {
  switch (action.type) {
    case 'initialisation':
      return action.payload || [];
    case 'add':
      if (action.payload) {
        return [...state, action.payload];
      } else {
        return state;
      }
    case 'remove':
      return state.filter((fee) => fee.position !== action.payload?.position);
    default:
      return state;
  }
}

function representativeReducer(
  state: Representative[],
  action: Action<Representative>
): Representative[] {
  switch (action.type) {
    case 'initialisation':
      console.log('initialisation', action.payload);
      return action.payload || [];
    case 'add':
      if (action.payload) {
        return [...state, action.payload];
      } else {
        return state;
      }
    case 'remove':
      return state.filter((fee) => {
        for (let key in action.payload) {
          if (action.payload[key as keyof Representative] !== fee[key as keyof Representative]) {
            return true;
          }
        }
        return false;
      });
    default:
      return state;
  }
}

export default function EditProposalPage() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { proposalId } = location.state as { proposalId: string };

  const [id, setId] = useState<string>('');
  const [title, setTitle] = useState<string | null>(null);
  const [input, setInput] = useState<string | null>(null);
  const [client, setClient] = useState<{ name: string; company: string }>({
    name: '',
    company: '',
  });
  const [introDefault, setIntroDefault] = useState(t('introLetter'));
  const [intro, setIntro] = useState<string>(introDefault);
  const [scope, setScope] = useState<string | null>(null);
  const [assumptions, setAssumptions] = useState<string | null>(null);
  const [fees, dispatchFees] = useReducer(feeReducer, [] as FeeProposal[]);
  const [representatives, dispatchRepresentatives] = useReducer(
    representativeReducer,
    [] as Representative[]
  );
  const [experience, setExperience] = useState<Experience[]>([]); // Manage experiences state
  const [lawyerList, setLawyerList] = useState<{ [key: string]: string }>({});
  const [selectedLawyers, setSelectedLawyers] = useState<{
    [key: string]: { name: string; bio: string };
  }>({}); // State for selected lawyers
  const [experienceIntro, setExperienceintro] = useState<string | null>(null);

  const [ws, setWs] = useState<WebSocket | null>(null);

  const [lawyerLoadingState, setlawyerLoadingState] = useState<string | null>(null); //feedback loading info
  const [experienceLoadingState, setExperiencesLoadingState] = useState<string | null>(null);

  // Create a state called loading which is a bool
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLawyers, setLoadingLawyers] = useState<boolean>(false);
  const [loadingExperiences, setLoadingExperiences] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [documentLoading, setDocumentLoading] = useState<boolean>(false);

  // create navigate hook
  const navigate = useNavigate();

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getLawyerList(accessToken);
        setLawyerList(response); // Ensure fileList is always an array
      } catch (error) {
        console.error('Error fetching lawyer list:', error);
        alert('Failed to fetch lawyer list.');
      }
    };

    fetchFileList();
  }, []);

  useEffect(() => {
    const updateIntroDefault = () => {
      const updatedIntro = t('introLetter'); // Fetch the updated translation
      setIntroDefault(updatedIntro); // Update introDefault state

      setIntro(updatedIntro);
    };
    // Run the effect initially to set the default
    updateIntroDefault();
    // Listen for language change
    i18n.on('languageChanged', updateIntroDefault);
    // Cleanup: Remove event listener when the component is unmounted
    return () => {
      i18n.off('languageChanged', updateIntroDefault);
    };
  }, [i18n, t]); // Dependencies ensure effect runs on language or intro changes

  useEffect(() => {
    setId(proposalId);
    async function getData() {
      console.log('Fetching proposal from id');
      const accessToken = await getAccessTokenSilently();
      const fetchedData = await fetchProposal(accessToken, proposalId);
      if (fetchedData) {
        setExperience(fetchedData.experience || []);
        setIntro(fetchedData.intro);
        setTitle(fetchedData.title);
        setInput(fetchedData.Input);
        setClient(fetchedData.client);
        setScope(fetchedData.scope);
        setAssumptions(fetchedData.assumptions);
        dispatchFees({ type: 'initialisation', payload: fetchedData.fees });
        dispatchRepresentatives({ type: 'initialisation', payload: fetchedData.representatives });
        setSelectedLawyers(fetchedData.lawyers);
        setExperienceintro(fetchedData.experienceIntro);
      }
      console.log(fetchedData);
    }
    getData();
  }, [proposalId]);

  // Create a function to handle the save button
  function handleSave() {
    // Aggregate the id, experience, intro title and current date into a Proposal

    console.log('representatives:', representatives);
    const proposal: Proposal = {
      id: id,
      experience: experience || [],
      intro: intro || '',
      title: title || 'New proposal',
      client: client || { name: '', company: '' },
      scope: scope || '',
      assumptions: assumptions || '',
      fees: fees || [],
      lawyers: selectedLawyers || '',
      last_edited: new Date(),
      user_metadata: user && user.user_metadata,
      representatives: representatives || [],
      experienceIntro: experienceIntro || '',
    };
    // Save the proposal
    async function save() {
      const accessToken = await getAccessTokenSilently();
      await saveProposal(accessToken, proposal);
      const newPath = location.pathname.split('/').slice(0, -1).join('/');
      navigate(newPath);
    }
    save();
  }

  // Create a function to handle the discard button
  function handleDiscard() {
    // Navigate to the parent route if in /test/edit-proposal go to /test
    const newPath = location.pathname.split('/').slice(0, -1).join('/');
    navigate(newPath);
  }

  useEffect(() => {
    if (!loadingLawyers && !loadingExperiences && ws) {
      console.log('Both tasks are done. Closing WebSocket.');
      ws.close(); // Close the WebSocket when both tasks are done
    }
  }, [loadingLawyers, loadingExperiences, ws]); // Watching loading states and WebSocket

  function handleLawyerGenerationMessage(message: any, ws: WebSocket) {
    if (message.type === 'selected_lawyers') {
      const lawyerNames = message.data;
      setSelectedLawyers((prevSelectedLawyers) => {
        const updatedSelectedLawyers = { ...prevSelectedLawyers };

        lawyerNames.forEach((lawyerName: string) => {
          if (!updatedSelectedLawyers[lawyerName]) {
            updatedSelectedLawyers[lawyerName] = { name: lawyerName, bio: '' };
          }
        });

        return updatedSelectedLawyers;
      });
    } else if (message.type === 'bio_response') {
      const { lawyer, bio } = message.data;
      setSelectedLawyers((prevSelectedLawyers) => ({
        ...prevSelectedLawyers,
        [lawyer]: { name: lawyer, bio },
      }));
    } else if (message.type === 'completed') {
      setLoadingLawyers(false);
      console.log('Lawyer loading: ', loadingLawyers);
      console.log('Expereinces loading: ', loadingExperiences);
    } else {
      setlawyerLoadingState(message.type);
    }
  }

  function handleExperienceGenerationMessage(message: any, ws: WebSocket) {
    if (message.type === 'selected_experiences') {
      const experiences = message.data;
      setExperience(experiences);
    } else if (message.type === 'completed') {
      setLoadingExperiences(false);
      console.log('Lawyer loading: ', loadingLawyers);
      console.log('Expereinces loading: ', loadingExperiences);
    } else {
      setExperiencesLoadingState(message.type);
    }
  }
  useEffect(() => {
    console.log('Updated loadingLawyers:', loadingLawyers);
    console.log('Updated loadingExperiences:', loadingExperiences);
    setLoading(loadingLawyers || loadingExperiences); // Update total loading state
  }, [loadingLawyers, loadingExperiences]);

  // Create handleGenerate which toggles the loading state from false to true or vice versa
  function handleGenerate() {
    //add permissions to lambda
    setLoadingLawyers(true);
    setLoadingExperiences(true);
    setLoading(true);
    console.log('Lawyer loading: ', loadingLawyers);
    console.log('Expereinces loading: ', loadingExperiences);

    async function generate() {
      if (typeof input !== 'string') {
        setLoadingLawyers(false);
        setLoadingExperiences(false);
        setLoading(false);
        console.log('no input');
        throw new Error('Input must be a string');
      }

      const accessToken = await getAccessTokenSilently();
      const newws = authWebSocket(accessToken);
      setWs(newws);
      newws.onmessage = (event) => {
        console.log(`Received message: ${event.data}`);
        const data = JSON.parse(event.data);
        if (data.source === 'offer_creation_lawyers') {
          handleLawyerGenerationMessage(data, newws);
        } else if (data.source == 'offer_creation_experiences') {
          handleExperienceGenerationMessage(data, newws);
        }
      };

      newws.onopen = () => {
        console.log(`WebSocket connection opened`);
        newws.send(JSON.stringify({ action: 'generateLawyers', query: input }));
        console.log(`Sent message to generateLawyers`);
        newws.send(JSON.stringify({ action: 'generateExperiences', query: input }));
        console.log(`Sent message to generateExperiences`);
      };
    }
    generate();
  }
  // Sends all of the information in the fields to backend endpoint to generate a word document
  async function handleWordDocGenerate() {
    if (documentLoading) {
      return;
    }
    // Aggregate the id, experience, intro title and current date into a Proposal
    const proposal = {
      id: id,
      title: title || 'New proposal',
      client,
      intro,
      scope,
      assumptions,
      fees,
      lawyers: selectedLawyers,
      experience,
      user_metadata: user && user.user_metadata,
      representatives: representatives,
      experienceIntro,
      language: i18n.language,
    };
    // Save the proposal
    const accessToken = await getAccessTokenSilently();
    await generateWordDoc(accessToken, proposal, setDocumentLoading);
    // Will want to maybe redirect to a pdf / word doc viewer
    // const newPath = location.pathname.split('/').slice(0, -1).join('/');
    // navigate(newPath);
  }

  function handleDelete() {
    // Delete the proposal
    async function deleteProposalData() {
      const accessToken = await getAccessTokenSilently();
      await deleteProposal(accessToken, proposalId);
      const newPath = location.pathname.split('/').slice(0, -1).join('/');
      navigate(newPath);
    }
    deleteProposalData();
  }

  const editorStyles = {
    backgroundColor: '#f7f7f7', // Light grey background
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid #ddd',
    marginBottom: '1rem',
    color: '#333', // Text color
  };
  const toolbarStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center', // Center the toolbar options
    flexWrap: 'wrap' as 'wrap', // Wrap toolbar if needed
    gap: '12px', // Add space between buttons
    padding: '8px', // Add padding around toolbar
    fontSize: '18px', // Increase font size of buttons
  };

  const contentStyles = {
    backgroundColor: '#ffffff', // Light grey background for the editable area
    padding: '12px', // Padding inside the text area
    borderRadius: '4px',
    border: '1px solid #ccc', // Light grey border for the text area
    color: '#333', // Text color inside the editor
  };

  return (
    <div style={{ width: '80%' }}>
      <h1>
        {t('editProposal')} - {title}
      </h1>
      <h2>{t('title')}</h2>
      <TextInput
        value={title || ''}
        placeholder={t('addYourTitleHere')}
        onChange={(event) => setTitle(event.target.value)}
        style={{ width: '30%' }} // Add this line to set the width of the text input
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ marginRight: '0.5rem' }}>{t('caseInfo')}</h2>
        <Tooltip label={t('provideCaseInfo')} withArrow multiline w={220} position="right">
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      <Textarea
        value={input || ''}
        placeholder={t('addYourInputTextHere')}
        onChange={(event) => setInput(event.target.value)}
        variant="filled" // Add this line to make the textarea a filled variant
        autosize
        minRows={6} // Add this line to set the minimum rows of the textarea to
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <Button
          color="cyan"
          variant="light"
          leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
          style={{ marginRight: '0.5rem' }}
          onClick={handleGenerate}
        >
          {t('generateProposal')}
        </Button>
      </div>
      <Divider my="xl" size="sm" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title order={1} style={{ marginRight: '1rem' }}>
          {t('lawyerInput')}
        </Title>
        <Tooltip label={t('humanInput')} withArrow position="right" w={220} multiline>
          <IconInfoCircle size={24} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      <h2>{t('client')}</h2>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Input.Wrapper label={t('clientCompany')} style={{ width: '30%' }}>
          <TextInput
            value={(client && client.company) || ''}
            // placeholder={t('clientCompany')}
            onChange={(event) => {
              if (
                !checkIntroChangedTooMuch(intro, t('introLetter'), t('introLine1'), t('introRest'))
              ) {
                const newIntro = introDefault
                  .replace(/__company__/g, event.target.value)
                  .replace(/__name__/g, client.name);
                setIntro(newIntro);
              }
              setClient({ name: client.name, company: event.target.value });
            }}
            // variant="filled" // Add this line to make the text input a filled variant
            // Add this line to set the width of the text input
          />
        </Input.Wrapper>
        <Input.Wrapper label={t('clientName')} style={{ width: '30%' }}>
          <TextInput
            value={(client && client.name) || ''}
            // placeholder={t('clientName')}
            onChange={(event) => {
              if (
                !checkIntroChangedTooMuch(intro, t('introLetter'), t('introLine1'), t('introRest'))
              ) {
                const newIntro = introDefault
                  .replace(/__name__/g, event.target.value)
                  .replace(/__company__/g, client.company);
                setIntro(newIntro);
              }
              setClient({ name: event.target.value, company: client.company });
            }}
            // variant="filled" // Add this line to make the text input a filled variant
            // Add this line to set the width of the text input
          />
        </Input.Wrapper>
      </div>
      <h2>{t('introduction')}</h2>
      <Textarea
        value={intro || ''}
        placeholder={t('addYourIntroHere')}
        onChange={(event) => setIntro(event.target.value)}
        variant="filled" // Add this line to make the textarea a filled variant
        autosize
        minRows={6} // Add this line to set the minimum rows of the textarea to 6
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h2>WKB Representatives</h2>
        <Tooltip
          label="The WKB representatives writing the letter to the client"
          withArrow
          multiline
          position="right"
          w={220}
        >
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      <EditableTable
        rows={representatives}
        dispatchRows={dispatchRepresentatives}
        columns={[
          {
            title: 'Name and Surname',
            key: 'name',
          },
          {
            title: 'Position',
            key: 'position',
          },
        ]}
      />
      <h2>{t('scopeOfWork')}</h2>
      {/* <TextEditor content={scope} onUpdate={setScope} placeholder={t('addScopeOfWorkHere')} /> */}
      <Textarea
        value={scope || ''}
        placeholder={t('addScopeOfWorkHere')}
        onChange={(event) => setScope(event.target.value)}
        variant="filled"
        autosize
        minRows={6}
      />
      <h2>{t('assumptions')}</h2>
      {/* <TextEditor
        content={assumptions}
        onUpdate={setAssumptions}
        placeholder={t('addAssumptionsHere')}
      /> */}
      <Textarea
        value={assumptions || ''}
        placeholder={t('addAssumptionsHere')}
        onChange={(event) => setAssumptions(event.target.value)}
        variant="filled"
        autosize
        minRows={6}
      />
      <h2>{t('fees')}</h2>
      <FeeTable fees={fees} dispatchFees={dispatchFees} />
      <h2>{t('experienceIntro')}</h2>
      <Textarea
        value={experienceIntro || ''}
        placeholder={t('addExperienceIntroHere')}
        onChange={(event) => setExperienceintro(event.target.value)}
        variant="filled"
        autosize
        minRows={6}
      />

      <Divider my="xl" size="sm" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title order={1} style={{ marginRight: '1rem' }}>
          {t('generatedByAI')}
        </Title>
        <Tooltip label={t('llmAssignedLawyers')} withArrow multiline position="right" w={220}>
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            color="cyan"
            variant="light"
            leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
            style={{ marginRight: '0.5rem' }}
            onClick={handleGenerate}
          >
            {t('generateProposal')}
          </Button>
        </div>
      </div>
      <h2 style={{ display: 'flex', alignItems: 'center' }}>
        {t('lawyers')}
        {loadingLawyers && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
            <Loader size="xs" color="blue" />
            <span style={{ marginLeft: '0.5rem', fontSize: '50%' }}>
              {t('loading')} {lawyerLoadingState}
            </span>
          </div>
        )}
      </h2>
      <LawyerTable
        lawyerList={lawyerList}
        selectedLawyers={selectedLawyers}
        setSelectedLawyers={setSelectedLawyers}
      />

      <h2 style={{ display: 'flex', alignItems: 'center' }}>
        {t('experience')}
        {loadingExperiences && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
            <Loader size="xs" color="blue" />
            <span style={{ marginLeft: '0.5rem', fontSize: '50%' }}>
              {t('loading')} {experienceLoadingState}
            </span>
          </div>
        )}
      </h2>
      <ExperienceTable experiences={experience} setExperiences={setExperience} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginTop: '1rem',
          gap: '12px',
        }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '12px' }}
        >
          <Button
            color="green"
            variant="light"
            leftSection={<IconCheck size={18} />}
            onClick={handleSave}
          >
            {t('save')}
          </Button>
          <Button
            color="blue"
            variant="light"
            leftSection={
              documentLoading ? <Loader size="xs" color="blue" /> : <IconPlus size={18} />
            }
            onClick={handleWordDocGenerate}
          >
            {t('generateWordDoc')}
          </Button>
          <Button
            color="gray"
            variant="light"
            leftSection={<IconX size={18} />}
            onClick={handleDiscard}
          >
            {t('cancelChanges')}
          </Button>
          <Button
            color="red"
            variant="light"
            leftSection={<IconTrash size={18} />}
            onClick={() => setIsModalOpen(true)}
          >
            {t('deleteProposal')}
          </Button>
        </div>
        <Modal
          opened={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={t('confirmDeletion')}
        >
          <div>{t('confirmDeleteProposal')}</div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '12px' }}
          >
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              {t('cancel')}
            </Button>
            <Button color="red" onClick={handleDelete}>
              {t('confirm')}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

// It's very possible this could come across a very funny edge case
function checkIntroChangedTooMuch(
  intro: string,
  introDefault: string,
  line1: string,
  theRest: string
) {
  console.log('line1 ', intro.includes(line1));
  console.log('rest ', intro.includes(theRest));
  console.log('the math ', Math.abs(intro.length - introDefault.length) < 60);
  if (
    intro.includes(line1) &&
    intro.includes(theRest) &&
    Math.abs(intro.length - introDefault.length) < 60
  ) {
    return false;
  }
  return true;
}
