import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import AuthLayout from './auth/AuthLayout';

import Root from './components/Root';
import ErrorPage from './pages/Error';
import AuthRoot from './auth/AuthRoot';
import ProtectedRoute from './auth/ProtectedRoute';
import ProtectedPage from './pages/Protected/index';
import AuthPage from './pages/Auth';
import Unprotected from './pages/Unprotected';
import ProposalsPage from './pages/Proposals';
import EditProposalPage from './pages/EditProposal';
import FileUpload from './pages/FileUpload';
import Account from './pages/Account/Account';
import HomeRedirect from './components/HomeRedirect';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthLayout />}>
      {/* <Route index element={<Unprotected />} /> */}
      <Route path="/" element={<AuthRoot />}>
        <Route path="/" element={<Root />}>
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="file-upload" index element={<FileUpload />} />
            <Route path="proposals" element={<ProposalsPage />} />
            <Route path="proposals/edit-proposal" element={<EditProposalPage />} />
            <Route path="account" element={<Account />} />
            <Route index element={<HomeRedirect />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export function Router() {
  return <RouterProvider router={router} />;
}
