import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { IconTrash } from '@tabler/icons-react';
import { Modal, Button } from '@mantine/core';
import { removeFile, getFilelist, getPresignedURL } from '@/services/removeFiles';
import { useTranslation } from 'react-i18next';

const FileUpload: React.FC = () => {
  const [fileList, setFileList] = useState<string[]>([]); // Initialize as an empty array
  const [filteredFiles, setFilteredFiles] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // State to store search term
  const [fileToDelete, setFileToDelete] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getFilelist(accessToken);
        const sortedFiles = (response.files || []).sort((a: string, b: string) =>
          a.localeCompare(b)
        ); // Sort alphabetically
        setFileList(sortedFiles); // Ensure fileList is always an array
        setFilteredFiles(sortedFiles);
      } catch (error) {
        console.error('Error fetching file list:', error);
        alert(t('errorFetchingAlert'));
      }
    };

    fetchFileList();
  }, []);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getPresignedURL(accessToken, file.name, file.type);
        console.log(response);
        const url = response.url;
        console.log('Generated Pre-Signed URL:');

        await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        alert(t('succesfulFileUpload'));
        setFileList((prevList) => {
          const updatedList = [...prevList, file.name].sort((a, b) => a.localeCompare(b));
          setFilteredFiles(updatedList); // Update filtered list after upload
          return updatedList;
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        alert(t('unsuccesfulUploadAlert'));
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'], // Accept PDF files
      'text/plain': ['.txt'], // Accept text files
      'application/msword': ['.doc'], // Accept .doc files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], // Accept .docx files
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'], // Accept .pptx files
    },
  });

  const handleDeleteClick = (filename: string) => {
    setFileToDelete(filename);
    setIsModalOpen(true);
  };

  function confirmDelete() {
    const filename: string = fileToDelete;
    async function remove() {
      setFileList((prevList) => {
        const updatedList = prevList
          .filter((file) => file !== fileToDelete)
          .sort((a, b) => a.localeCompare(b));

        const updatedFilteredFiles = updatedList.filter((file) =>
          file.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredFiles(updatedFilteredFiles); // Update the filtered list

        return updatedList; // Update the full file list
      });
      const accessToken = await getAccessTokenSilently();
      await removeFile(accessToken, filename);
    }
    remove();
    setIsModalOpen(false);
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredFiles(fileList); // Reset the filtered list when search is cleared
    } else {
      const filtered = fileList.filter((file) => file.toLowerCase().includes(value.toLowerCase()));
      setFilteredFiles(filtered); // Update filtered list based on search term
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={{ border: '2px dashed #0087F7', padding: '20px', textAlign: 'center' }}
      >
        <input {...getInputProps()} />
        <p>{t('dragDrop')}</p>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3>{t('uploadedFiles')}</h3>
        <div style={{ marginTop: '20px' }}>
          <input
            type="text"
            placeholder={t('searchFiles')}
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
          />
        </div>
        {filteredFiles.length > 0 ? (
          <div
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              padding: '10px',
              width: '100%',
            }}
          >
            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
              {filteredFiles.map((file) => (
                <li
                  key={file}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0',
                    wordWrap: 'break-word',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <span style={{ flex: 1, wordBreak: 'break-all' }}>{file}</span>{' '}
                  {/* Ensures long text wraps */}
                  <IconTrash
                    style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                    onClick={() => handleDeleteClick(file)}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>{t('noMatchFiles')}</p>
        )}
      </div>

      {/* Modal for delete confirmation */}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('confirmDeletion')}
      >
        <p>
          {t('deleteFileConfirmation')} {fileToDelete}?
        </p>
        <Button color="red" onClick={confirmDelete}>
          {t('confirm')}
        </Button>
        <Button
          variant="outline"
          onClick={() => setIsModalOpen(false)}
          style={{ marginLeft: '10px' }}
        >
          {t('cancel')}
        </Button>
      </Modal>
    </div>
  );
};

export default FileUpload;
