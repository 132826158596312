import { authGet, authPost } from '../auth/authFetches';

export async function fetchAccountData(accessToken: string, user_id: string) {
  const path = `/accountData/getUser/${user_id}`;
  return await authGet(accessToken, path);
}

export async function updateAccountData(accessToken: string, user_id: string, body: any) {
  const path = `/accountData/updateUser/${user_id}`;
  return await authPost(accessToken, path, body);
}
