import { Table, Anchor, Button, Input, Center } from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProposalList, deleteProposal } from '@/services/proposalsRoutes';
import { IconX, IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
import { useTranslation } from 'react-i18next';

// A componenet that generates a table row when given a row from proposalData
function ProposalRow({
  row,
  dispatchRows,
  columns,
}: {
  row: { [key: string]: any };
  dispatchRows: React.Dispatch<any>;
  columns: { title: string; key: string }[];
}) {
  const handleDeleteClick = () => {
    async function deleteProposalData() {
      dispatchRows({ type: 'remove', payload: row });
    }
    deleteProposalData();
  };

  return (
    <Table.Tr>
      {columns.map((column) => {
        return <Table.Td>{row[column.key]}</Table.Td>;
      })}

      <Table.Td style={{ textAlign: 'right' }}>
        <Center>
          <Button variant="light" color="red" size="compact-xs" onClick={handleDeleteClick}>
            <IconX size={16} />
          </Button>
        </Center>
      </Table.Td>
    </Table.Tr>
  );
}

type miscRow = { [key: string]: string | number | undefined };

export default function EditableTable({
  rows,
  dispatchRows,
  columns,
}: {
  rows: miscRow[] | undefined;
  dispatchRows: React.Dispatch<any>;
  columns: { title: string; key: string }[];
}) {
  const { t } = useTranslation();

  const initialRow: miscRow = {};
  columns.forEach((column) => {
    initialRow[column.key] = '';
  });
  const [newRow, setNewRow] = useState<miscRow>(initialRow);

  const handleAddRow = () => {
    dispatchRows({ type: 'add', payload: newRow });
    setNewRow(initialRow);
  };

  const changeHandlers = columns.map((columns) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewRow({ ...newRow, [columns.key]: e.target.value });
    };
  });

  // const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewRow({ ...newRow, position: e.target.value });
  // };

  // const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewRow({ ...newRow, rate: parseFloat(e.target.value) });
  // };
  console.log(rows);

  return (
    <Table.ScrollContainer minWidth={600} w="80%">
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{columns[0].title}</Table.Th>
            <Table.Th>{columns[1].title}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows &&
            rows.map((row) => (
              <ProposalRow
                key={row[columns[0].key]}
                row={row}
                dispatchRows={dispatchRows}
                columns={columns}
              />
            ))}
          <Table.Tr>
            {columns.map((column, idx) => {
              return (
                <Table.Td>
                  <Input value={newRow[columns[idx].key]} onChange={changeHandlers[idx]} />
                </Table.Td>
              );
            })}
            {/* <Table.Td>
              <Input type="number" value={newRow[columns[1].key]} onChange={changeHandlers} />
            </Table.Td> */}
            <Table.Td>
              <Center>
                <Button variant="light" color="green" size="sm" onClick={handleAddRow}>
                  {t('addRow')}
                </Button>
              </Center>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
