import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // English translations
      welcome: 'Welcome',
      addRow: 'Add Row',
      removeRow: 'Remove Row',
      createNew: 'Create New',
      edit: 'Edit',
      delete: 'Delete',
      logIn: 'Log In',
      logOut: 'Log Out',
      fileUpload: 'File Upload',
      proposals: 'Proposals',
      switchLanguage: 'Switch Language',
      changeAccount: 'Change Account',
      proposal: 'Proposal',
      dateEdited: 'Date Edited',
      editProposal: 'Edit Proposal',
      title: 'Title',
      description: 'Description',
      caseInfo: 'Case Information',
      lawyerInput: 'Lawyer Input',
      generateProposal: 'Generate Proposal',
      scopeOfWork: 'Scope of Work',
      introduction: 'Introduction',
      assumptions: 'Assumptions',
      fees: 'Fees',
      position: 'Position',
      rate: 'Rate (EUR)',
      generatedByAI: 'Generated by AI',
      lawyers: 'Lawyers',
      experience: 'Experience',
      projectTitle: 'Project Title',
      client: 'Client',
      addExperience: 'Add Experience',
      addLawyer: 'Add Lawyer',
      save: 'Save',
      cancelChanges: 'Cancel Changes',
      generateWordDoc: 'Generate Word Document',
      deleteProposal: 'Delete Proposal',
      addYourTitleHere: 'Add your title here',
      provideCaseInfo:
        'Provide information pertaining to the case e.g. an email from a potential client or simply a description of it. This will be used by an LLM to assign lawyers automatically.',
      addYourInputTextHere: 'Add your input text here.',
      clientName: 'Client Representative',
      clientCompany: 'Client company',
      addYourIntroHere: 'Add your intro here',
      addScopeOfWorkHere: 'Add scope of work here',
      addAssumptionsHere: 'Add assumptions here',
      llmAssignedLawyers:
        'An LLM has automatically assigned lawyers and explained their suitability for the task given their experience',
      loading: 'Loading...',
      confirmDeletion: 'Confirm Deletion',
      confirmDeleteProposal: 'Are you sure you want to delete this proposal?',
      confirm: 'Confirm',
      humanInput: 'The fields below should be filled in by a human',
      introLetter: `
Dear _____

thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for _____.

In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.

In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 

If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.

Kind regards,
`,
      introLine1:
        'thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for',
      introRest: `In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.

In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 

If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.

Kind regards,`,
      uploadedFiles: 'Uploaded Files',
      searchFiles: 'Search files...',
      dragDrop: "Drag 'n' drop some files here, or click to select files",
      errorFetchingAlert: 'Failed to fetch file list.',
      succesfulFileUpload: 'File uploaded successfully!',
      unsuccesfulUploadAlert: 'Failed to upload file.',
      noMatchFiles: 'No files match the search criteria.',
      cancel: 'Cancel',
      deleteFileConfirmation: 'Are you sure you want to delete',
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      phoneNumber: 'Phone Number',
      positionAccountPage: 'Position',
      location: 'Location',
      userDetails: 'User Details',
      addDescription: 'Add description',
      addBio: 'Add biography',
      experienceIntro: 'Experience Introduction',
      addExperienceIntroHere: 'Add experience introduction here',

      // Add other translations here
      account: 'Account',
    },
  },
  pl: {
    translation: {
      // Polish translations
      welcome: 'Witamy',
      addRow: 'Dodaj wiersz',
      removeRow: 'Usuń wiersz',
      createNew: 'Utwórz nowy',
      edit: 'Edytuj',
      delete: 'Usuń',
      logIn: 'Zaloguj',
      logOut: 'Wyloguj',
      fileUpload: 'Przesyłanie plików',
      proposals: 'Propozycje',
      switchLanguage: 'Zmień język',
      changeAccount: 'Zmień konto',
      proposal: 'Propozycja',
      dateEdited: 'Data edycji',
      editProposal: 'Edytuj propozycję',
      title: 'Tytuł',
      description: 'Opis',
      caseInfo: 'Informacje o sprawie',
      lawyerInput: 'Dane prawnika',
      generateProposal: 'Generuj propozycję',
      scopeOfWork: 'Zakres Doradztwa',
      introduction: 'Wstęp',
      assumptions: 'Założenia',
      fees: 'Propozycja Wynagrodzenia',
      position: 'Stanowisko',
      rate: 'Stawka (EUR)',
      generatedByAI: 'Wygenerowane przez AI',
      lawyers: 'Prawnicy',
      experience: 'Przykładowe doświadczenie',
      projectTitle: 'Tytuł projektu',
      client: 'Klient',
      addExperience: 'Dodaj doświadczenie',
      addLawyer: 'Dodaj prawnika',
      save: 'Zapisz',
      cancelChanges: 'Anuluj zmiany',
      generateWordDoc: 'Generuj dokument Word',
      deleteProposal: 'Usuń propozycję',
      addYourTitleHere: 'Dodaj swój tytuł tutaj',
      provideCaseInfo:
        'Podaj informacje dotyczące sprawy, np. e-mail od potencjalnego klienta lub po prostu jej opis. Będzie to wykorzystane przez LLM do automatycznego przypisania prawników.',
      addYourInputTextHere: 'Dodaj swój tekst wejściowy tutaj.',
      clientName: 'Przedstawiciel klienta',
      clientCompany: 'Firma klienta',
      addYourIntroHere: 'Dodaj swój wstęp tutaj',
      addScopeOfWorkHere: 'Dodaj zakres pracy tutaj',
      addAssumptionsHere: 'Dodaj założenia tutaj',
      llmAssignedLawyers:
        'LLM automatycznie przypisał prawników i wyjaśnił ich odpowiedniość do zadania w oparciu o ich doświadczenie',
      loading: 'Ładowanie...',
      confirmDeletion: 'Potwierdź usunięcie',
      confirmDeleteProposal: 'Czy na pewno chcesz usunąć tę propozycję?',
      confirm: 'Potwierdź',
      humanInput: 'Poniższe pola powinny zostać wypełnione przez użytkownika',
      introLetter: `
Szanowna Pani/Panie _____

dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]

W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.

W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.

W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.

Z poważaniem`,

      introLine1:
        'dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]',
      introRest: `W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.

W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.

W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.

Z poważaniem`,
      uploadedFiles: 'Przesłane pliki',
      searchFiles: 'Wyszukaj w plikach...',
      dragDrop: 'Przeciągnij i upuść pliki tutaj lub kliknij, aby wybrać pliki',
      errorFetchingAlert: 'Nie udało się pobrać listy plików.',
      succesfulFileUpload: 'Plik przesłany pomyślnie!',
      unsuccesfulUploadAlert: 'Nie udało się przesłać pliku.',
      noMatchFiles: 'Brak plików spełniających kryteria wyszukiwania.',
      cancel: 'Anuluj',
      deleteFileConfirmation: 'Czy na pewno chcesz usunąć',
      name: 'Imię',
      surname: 'Nazwisko',
      email: 'Email',
      phoneNumber: 'Numer telefonu',
      positionAccountPage: 'Stanowisko',
      location: 'Lokalizacja',
      userDetails: 'Dane użytkownika',
      addDescription: 'Dodaj opis',
      addBio: 'Dodaj biografię',
      experienceIntro: 'Wstęp do doświadczenia',
      addExperienceIntroHere: 'Dodaj wstęp do doświadczenia',

      // Add other translations here
      account: 'Konto',
    },
  },
};

i18n
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'pl', // Default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
