import React, { useState, useEffect, useRef } from 'react';
import { Table, Textarea, Button, Box } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { Experience } from './types';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../components/TextEditor/TextEditor';

type ExperienceTableProps = {
  experiences: Experience[];
  setExperiences: React.Dispatch<React.SetStateAction<Experience[]>>;
};

const ExperienceRow: React.FC<{
  experience: Experience;
  index: number;
  rowRefs: React.MutableRefObject<(HTMLTextAreaElement | null)[][]>;
  handleInputChange: (index: number, field: keyof Experience, value: string) => void;
  removeRow: (index: number) => void;
}> = ({ experience, index, rowRefs, handleInputChange, removeRow }) => {

  const { t } = useTranslation();

  return (
    <Table.Tr key={index}>
      <Table.Td>
        <Box style={{ paddingBottom: '1rem' }}>

          <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
            <Box style={{ width: '48%' }}>
              <h3>{t('projectTitle')}</h3>
              <Textarea
                ref={(el) => {
                  rowRefs.current[index] = rowRefs.current[index] || [];
                  rowRefs.current[index][0] = el;
                }}
                placeholder="Add project title"
                value={experience.title}
                onChange={(event) =>
                  handleInputChange(index, 'title', event.currentTarget.value)
                }
                autosize
                style={{ resize: 'vertical' }}
              />
            </Box>
            <Box style={{ width: '48%' }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>{t('client')}</h3>
                <Button
                  variant="light"
                  color="red"
                  size="compact-xs"
                  onClick={() => removeRow(index)}
                >
                  <IconX size={16} />
                </Button>
              </Box>
              <Textarea
                ref={(el) => {
                  rowRefs.current[index] = rowRefs.current[index] || [];
                  rowRefs.current[index][2] = el;
                }}
                placeholder="Add client"
                value={experience.client}
                onChange={(event) =>
                  handleInputChange(index, 'client', event.currentTarget.value)
                }
                autosize
                style={{ resize: 'vertical' }}
              />
            </Box>
          </Box>
          <h3>{t('description')}</h3>
          {/* <TextEditor
            content={experience.description}  // Pass the current description as content
            onUpdate={(value) => handleInputChange(index, 'description', value || '')} // Update the description on change
            placeholder={t('addDescription')}
          /> */}
          <Textarea
            value={experience.description || ''}
            placeholder={t('addDescription')}
            onChange={(event) => handleInputChange(index, 'description', event.target.value || '')}
            variant="filled"
            autosize
            minRows={6}
          />

        </Box>
      </Table.Td>
    </Table.Tr >
  );
}

const ExperienceTable: React.FC<ExperienceTableProps> = ({ experiences, setExperiences }) => {
  const rowRefs = useRef<(HTMLTextAreaElement | null)[][]>([]);
  const { t } = useTranslation();

  // Adds a new empty experience row
  const addRow = () => {
    setExperiences((prev) => [...prev, { title: '', description: '', client: '', team: '' }]);
  };

  // Removes an experience row
  const removeRow = (index: number) => {
    setExperiences((prev) => prev.filter((_, i) => i !== index));
  };

  // Updates the project, description, or client for a specific experience
  const handleInputChange = (index: number, field: keyof Experience, value: string) => {
    const updatedExperiences = experiences.map((experience, i) =>
      i === index ? { ...experience, [field]: value } : experience
    );
    setExperiences(updatedExperiences);
  };

  return (
    <div>
      <Table>
        <Table.Tbody>
          {Array.isArray(experiences) &&
            experiences.map((experience, index) => (
              <ExperienceRow
                key={index}
                experience={experience}
                index={index}
                rowRefs={rowRefs}
                handleInputChange={handleInputChange}
                removeRow={removeRow}
              />

            ))}
        </Table.Tbody>
      </Table>
      <Button variant="light" onClick={addRow} mt="md">
        {t('addExperience')}
      </Button>
    </div>
  );
};

export default ExperienceTable;
