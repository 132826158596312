import { authPost, authGet } from '../auth/authFetches';

export async function removeFile(accessToken: string, filename: string) {
    const path = `/files/removeFile`;
    return await authPost(accessToken, path, { filename });
}

export async function getFilelist(accessToken: string) {
    const path = '/files/get-filelist';
    return await authGet(accessToken, path)
}

export async function getPresignedURL(accessToken: string, filename: string, filetype: string) {
    const path = '/files/generate-presigned-url'
    const params = {filename, filetype}
    return await authPost(accessToken, path, params)
}