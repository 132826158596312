import { useState, useEffect } from 'react';
import { TextInput, Button, Stack, Paper, Group, Title, Center } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchAccountData, updateAccountData } from '@/services/account';
import { useTranslation } from 'react-i18next';

interface AccountData {
  given_name: string;
  family_name: string;
  email: string;
  phone: string;
  position: string;
  location: string;
}

const Account: React.FC = () => {
  const { t } = useTranslation();

  const [accountData, setAccountData] = useState<AccountData>({
    given_name: '',
    family_name: '',
    email: '',
    phone: '',
    position: '',
    location: '',
  });

  const { getAccessTokenSilently, user } = useAuth0();
  useEffect(() => {
    const getAccountData = async () => {
      const accessToken = await getAccessTokenSilently();
      if (user) {
        const response = await fetchAccountData(accessToken, user.sub || '');
        if (response) {
          // for (let key in response.user_metadata) {
          //   response[key] = response.user_metadata[key] || '';
          // }
          setAccountData(response);
        }
      }
    };
    getAccountData();
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAccountData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission here
    const accessToken = await getAccessTokenSilently();
    if (user) {
      updateAccountData(accessToken, user.sub || '', accountData);
    }
  };

  return (
    <Stack w="90%" align="center">
      <Title mb="lg" style={{ alignSelf: 'flex-start' }}>
        {t('account')}
      </Title>
      <form onSubmit={handleSubmit} style={{ width: '80%' }}>
        <Paper shadow="sm" p="xl" radius="md" withBorder mb="lg" w="80%" miw="350px">
          <Title order={3} style={{ marginBottom: '1rem' }}>
            {t('userDetails')}
          </Title>
          <Center>
            <Stack gap="md" w="50%" miw="300px">
              <TextInput
                label={t('name')}
                name="given_name"
                value={(accountData && accountData.given_name) || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('surname')}
                name="family_name"
                value={(accountData && accountData.family_name) || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('email')}
                name="email"
                value={(accountData && accountData.email) || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('phoneNumber')}
                name="phone"
                value={(accountData && accountData.phone) || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('positionAccountPage')}
                name="position"
                value={(accountData && accountData.position) || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('location')}
                name="location"
                value={(accountData && accountData.location) || ''}
                placeholder="Warsaw/Poznań"
                onChange={handleChange}
              />
              <Group style={{ justifyContent: 'flex-end' }} mt="lg">
                <Button type="submit" mt="md">
                  Save
                </Button>
              </Group>
            </Stack>
          </Center>
        </Paper>
      </form>
    </Stack>
  );
};

export default Account;
